import { Toaster as Sonner } from 'sonner';

import { useTheme } from '../theme-provider';

type ToasterProps = React.ComponentProps<typeof Sonner>;

const Toaster = ({ ...props }: ToasterProps) => {
  const { theme } = useTheme();

  return (
    <Sonner
      className="toaster group"
      theme={theme as ToasterProps['theme']}
      toastOptions={{
        classNames: {
          actionButton: 'group-[.toast]:bg-primary group-[.toast]:text-primary-foreground',
          cancelButton: 'group-[.toast]:bg-muted group-[.toast]:text-muted-foreground',
          description: 'group-[.toast]:text-muted-foreground',
          icon: 'self-start mt-[1px]',
          toast:
            'group toast group-[.toaster]:border-border group-[.toaster]:shadow-lg pointer-events-auto',
        },
        closeButton: true,
        duration: 5000,
      }}
      {...props}
    />
  );
};

export { Toaster };
