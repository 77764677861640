import { LoaderCircle } from 'lucide-react';

import { cn } from './utils';

export interface SpinnerProps {
  className?: string;
}

export function Spinner({ className }: SpinnerProps) {
  return <LoaderCircle className={cn('ease animate-spin', className)} role="progressbar" />;
}
