import DataDogLoggedIn from '@optools/data-dog/DataDogLoggedIn';
import { useOktaProfile } from '@optools/legacy-ui/hooks/useOktaProfile';
import { MLBRumInitConfiguration } from '@optools/services/config/types';
import { selectDataDogConfig } from '@optools/store/configApiSlice';
import { useAppSelector } from '@optools/store/hooks';
import { Fragment, ReactNode, useMemo } from 'react';

export interface DataDogProps {
  children: ReactNode;
}

function DataDogWrapper({ children }: DataDogProps) {
  const ddConfig = useAppSelector(selectDataDogConfig);
  const { profile } = useOktaProfile();
  const userInfo = profile
    ? { email: profile.email, id: profile.id, name: profile.fullName }
    : undefined;

  const rumConfig = useMemo(() => {
    return ddConfig?.rumConfig
      ? ({
          ...ddConfig.rumConfig,
          allowedTracingUrls: [/^(https?|wss):\/\/operator-gateway/],
        } satisfies MLBRumInitConfiguration)
      : undefined;
  }, [ddConfig]);

  return (
    <DataDogLoggedIn logsConfig={ddConfig?.logsConfig} rumConfig={rumConfig} userInfo={userInfo}>
      {children}
    </DataDogLoggedIn>
  );
}

const DataDog = process.env.NODE_ENV === 'production' ? DataDogWrapper : Fragment;

export default DataDog;
