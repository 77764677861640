import { ApolloError } from '@apollo/client';
import { SnackbarProvider } from 'notistack';
import { PropsWithChildren, useEffect } from 'react';

import ApolloErrorMessageDisplay from './ApolloErrorMessageDisplay';
import MessageDisplay from './MessageDisplay';
import { Message } from './types';
import { useMessageCenter } from './useMessageCenter';

declare module 'notistack' {
  interface VariantOverrides {
    apolloErrorMessageDisplay: {
      error: ApolloError;
    };
    messageDisplay: true;
  }
}

export function MessageCenterDebug() {
  const { closeMessage, enqueueMessage } = useMessageCenter();
  useEffect(() => {
    window.__addAlert = (message: Message) => enqueueMessage(message);

    window.__closeMessage = (messageId?: string) => {
      closeMessage(messageId);
    };
  }, [closeMessage, enqueueMessage]);
  return null;
}

export default function MessageCenter({ children }: PropsWithChildren) {
  return (
    <SnackbarProvider
      Components={{
        apolloErrorMessageDisplay: ApolloErrorMessageDisplay,
        messageDisplay: MessageDisplay,
      }}
      maxSnack={5}>
      <MessageCenterDebug />
      {children}
    </SnackbarProvider>
  );
}
