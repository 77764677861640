import { ReactNode, Suspense } from 'react';
import { Outlet } from 'react-router-dom';

import { PageLoader } from './loading/PageLoader';
import { OutletContainer, PageContainer } from './Page.styles';
import { SubNavigation, SubNavigationLinkProps } from './SubNavigation';

export interface PageProps {
  links?: SubNavigationLinkProps[];
  /** Suspense fallback override, PageLoader will be used by default */
  suspenseFallback?: ReactNode;
  title: string;
}

export function Page({ links, suspenseFallback, title }: PageProps) {
  return (
    <PageContainer>
      <SubNavigation links={links} title={title} />
      <Suspense fallback={suspenseFallback || <PageLoader />}>
        <OutletContainer>
          <Outlet />
        </OutletContainer>
      </Suspense>
    </PageContainer>
  );
}
