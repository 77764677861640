import { Button } from '@optools/ui/button';
import { Tooltip, TooltipContent, TooltipTrigger } from '@optools/ui/tooltip';
import { cn } from '@optools/ui/utils';
import { LucideProps } from 'lucide-react';
import { NavLink, To } from 'react-router-dom';

export interface SidebarLinkProps {
  icon: React.ForwardRefExoticComponent<
    Omit<LucideProps, 'ref'> & React.RefAttributes<SVGSVGElement>
  >;
  name: string;
  open: boolean;
  to: To;
}

export function SidebarLink({ icon, name, open, to }: SidebarLinkProps) {
  const Icon = icon;

  return (
    <Tooltip {...(open && { open: false })}>
      <TooltipTrigger asChild>
        <Button
          aria-label={name}
          asChild
          className={cn(
            'ease justify-start rounded-lg px-2.5 transition-all duration-300',
            open ? 'w-60' : 'w-10',
          )}
          variant="ghost">
          <NavLink className="[&[aria-current=page]]:bg-muted" to={to}>
            <Icon className="size-5 shrink-0" />
            <span
              className={cn(
                'linear ml-2 transition-opacity duration-300',
                open ? 'opacity-100 delay-100' : 'opacity-0',
              )}>
              {name}
            </span>
          </NavLink>
        </Button>
      </TooltipTrigger>
      <TooltipContent side="right" sideOffset={5}>
        {name}
      </TooltipContent>
    </Tooltip>
  );
}
