import { setupStore } from '@optools/store/store';
import * as React from 'react';
import * as ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';

import App from './App/components/App';

import './index.css';

async function enableApiMocking() {
  const { worker } = await import('@optools/test-utils/browser');
  await worker.start({ onUnhandledRequest: 'bypass' });
}

const store = setupStore();

function renderApp() {
  const root = ReactDOM.createRoot(document.getElementById('root') as Element);
  return root.render(
    <React.StrictMode>
      <Provider store={store}>
        <App />
      </Provider>
    </React.StrictMode>,
  );
}

API_MOCKING === 'enabled'
  ? enableApiMocking()
      .then(() => renderApp())
      .catch(console.error)
  : renderApp();
