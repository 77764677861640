import { TokenAPI } from '@okta/okta-auth-js';
import { OktaGroup } from '@optools/utils/auth';
import { createSelector } from '@reduxjs/toolkit';

import { apiSlice } from './apiSlice';

export interface OktaProfile {
  email: string;
  firstName: string;
  fullName: string;
  groups: OktaGroup[];
  id: string;
  lastName: string;
}

export const oktaApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    userProfile: builder.query<OktaProfile, TokenAPI['getUserInfo']>({
      keepUnusedDataFor: Infinity,
      queryFn: async (getUserInfo) => {
        try {
          const user = await getUserInfo();
          const { email, name, sub: id } = user;
          const userGroups = user['groups'] as OktaGroup[];

          const groups = userGroups instanceof Array ? userGroups : [userGroups];
          const fullName =
            user.given_name && user.family_name ? `${user.given_name} ${user.family_name}` : '';
          const data = {
            email: email ?? '',
            firstName: user.given_name ?? '',
            fullName,
            groups,
            id,
            lastName: user.family_name ?? '',
            name,
          };

          return { data };
        } catch (error: unknown) {
          return { error: `Okta Group Error: ${(error as Error)?.message}` };
        }
      },
    }),
  }),
});

export const { useUserProfileQuery } = oktaApiSlice;

// Selectors
export const selectInitials = createSelector(
  [(profile: OktaProfile | undefined) => profile],
  (profile: OktaProfile | undefined) => {
    if (!profile) return '';

    const { firstName, lastName } = profile;
    const [firstInitial] = firstName;
    const [lastInitial] = lastName;
    return `${firstInitial}${lastInitial}`;
  },
);
