import { getConfigEnv } from './storage';
import { APP_ENVIRONMENTS, CONFIG_ENVIRONMENTS } from './types';

export const determineAppEnvironment = (): APP_ENVIRONMENTS => {
  const host = typeof window !== 'undefined' ? window.location.host : '';

  if (
    (typeof process !== 'undefined' && process.env['NODE_ENV'] === 'test') ||
    /(-dev|-preview|local)/.test(host)
  ) {
    return APP_ENVIRONMENTS.DEV;
  }

  if (/(-qa)/.test(host)) {
    return APP_ENVIRONMENTS.QA;
  }

  if (/(-npd)/.test(host)) {
    return APP_ENVIRONMENTS.NPD;
  }

  return APP_ENVIRONMENTS.PROD;
};

export const determineConfigEnvironment = (appEnv: APP_ENVIRONMENTS): CONFIG_ENVIRONMENTS => {
  if (typeof process !== 'undefined' && process.env['NODE_ENV'] === 'test') {
    return CONFIG_ENVIRONMENTS.QA;
  }

  if (appEnv === APP_ENVIRONMENTS.DEV) {
    const env = getConfigEnv();
    return env;
  }

  if (appEnv === APP_ENVIRONMENTS.QA) {
    return CONFIG_ENVIRONMENTS.QA;
  }

  if (appEnv === APP_ENVIRONMENTS.NPD) {
    return CONFIG_ENVIRONMENTS.NPD;
  }

  return CONFIG_ENVIRONMENTS.PROD;
};
