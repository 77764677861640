import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import MessageCenter from '@optools/legacy-ui/components/messaging/MessageCenter';
import { Outlet, useOutletContext } from 'react-router-dom';

import Theme from './Theme';

export function MUIProviders() {
  const context = useOutletContext();

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Theme>
        <MessageCenter>
          <Outlet context={context} />
        </MessageCenter>
      </Theme>
    </LocalizationProvider>
  );
}
