import { MLBLogsInitConfiguration, MLBRumInitConfiguration } from '@optools/services/config/types';
import { ReactNode } from 'react';

import { useDataDogOnLogin } from './hooks/useDataDogOnLogin';
import { UserInfo } from './types';

export interface DataDogProps {
  children?: ReactNode;
  logsConfig?: MLBLogsInitConfiguration;
  rumConfig?: MLBRumInitConfiguration;
  userInfo?: UserInfo;
}

function DataDogLoggedIn({ children, logsConfig, rumConfig, userInfo }: DataDogProps) {
  useDataDogOnLogin({ logsConfig, rumConfig, userInfo });
  return children;
}

export default DataDogLoggedIn;
