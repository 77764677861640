import { Button } from '@optools/ui/button';
import { CircleAlert } from 'lucide-react';
import { ReactNode } from 'react';
import { ErrorBoundary as ReactErrorBoundary, FallbackProps } from 'react-error-boundary';

function ErrorFallback({ error }: FallbackProps) {
  return (
    <div className="mx-auto flex h-screen w-full max-w-3xl flex-col items-center justify-center">
      <div className="mb-4 flex items-center gap-2">
        <CircleAlert className="h-8 w-8 stroke-red-600" />

        <h1 className="text-4xl font-bold text-red-600">A critical error has occurred</h1>

        <CircleAlert className="h-8 w-8 stroke-red-600" />
      </div>

      <div className="mb-6 text-center">{error.message && <p>{error.message}</p>}</div>

      <Button
        className="mx-auto w-max"
        variant="destructive"
        onClick={() => window.location.reload()}>
        Reload
      </Button>
    </div>
  );
}

interface ErrorBoundaryProps {
  children: ReactNode;
}

export function ErrorBoundary({ children }: ErrorBoundaryProps) {
  return <ReactErrorBoundary FallbackComponent={ErrorFallback}>{children}</ReactErrorBoundary>;
}
