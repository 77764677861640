import Apollo from '@optools/services/providers/Apollo';
import { selectOperatorGatewayProxyConfig } from '@optools/store/configApiSlice';
import { useAppSelector } from '@optools/store/hooks';
import { ThemeProvider } from '@optools/ui/theme-provider';
import { TooltipProvider } from '@optools/ui/tooltip';
import { ReactNode } from 'react';
import { BrowserRouter } from 'react-router-dom';

import Auth from './Auth';

interface ProvidersProps {
  children: ReactNode;
}

export default function Providers({ children }: ProvidersProps) {
  const mlbOperatorGatewayProxyConfig = useAppSelector(selectOperatorGatewayProxyConfig);
  return (
    <ThemeProvider>
      <BrowserRouter>
        <Auth>
          <Apollo mlbOperatorGatewayProxyConfig={mlbOperatorGatewayProxyConfig}>
            <TooltipProvider>{children}</TooltipProvider>
          </Apollo>
        </Auth>
      </BrowserRouter>
    </ThemeProvider>
  );
}
