import { useOktaAuth } from '@okta/okta-react';
import { Outlet, useOutletContext } from 'react-router-dom';

function SecureOutlet() {
  const context = useOutletContext();
  const { authState } = useOktaAuth();
  const isAuthenticated = !!authState?.isAuthenticated;

  return isAuthenticated ? (
    <Outlet context={context} />
  ) : (
    <h1 className="p-4 text-2xl font-normal">You must be signed in to use this tool.</h1>
  );
}

export default SecureOutlet;
