import * as npdConfigFallback from '@optools/data/application-configs/npd/config.json';
import * as prodConfigFallback from '@optools/data/application-configs/prod/config.json';
import * as qaConfigFallback from '@optools/data/application-configs/qa/config.json';
import { getUseLocalConfig } from '@optools/services/config/storage';
import { CONFIG_ENVIRONMENTS, EnvironmentConfig } from '@optools/services/config/types';
import {
  determineAppEnvironment,
  determineConfigEnvironment,
} from '@optools/services/config/utils';
import { createSelector } from '@reduxjs/toolkit';
import axios from 'axios';
import { ReactNode } from 'react';

import { apiSlice } from './apiSlice';

export interface ConfigurationProviderProps {
  children?: ReactNode;
}

const appEnvironment = determineAppEnvironment();
const configEnvironment = determineConfigEnvironment(appEnvironment);
const configName = 'config.json';
const appVersion = APP_VERSION;
const baseUrl = 'https://storage.googleapis.com/';

const configurationEndpoints: { [key in CONFIG_ENVIRONMENTS]: string } = {
  npd: 'mlb-webeng-prod-mlb-static-beta/streaming-operator-platform/',
  prod: 'mlb-webeng-prod-mlb-static-prod/streaming-operator-platform/',
  qa: 'mlb-webeng-npd-mlb-static-qa/streaming-operator-platform/',
};

const defaultConfigs: { [key in CONFIG_ENVIRONMENTS]: EnvironmentConfig } = {
  npd: {
    ...(npdConfigFallback as EnvironmentConfig),
  },
  prod: {
    ...(prodConfigFallback as EnvironmentConfig),
  },
  qa: {
    ...(qaConfigFallback as EnvironmentConfig),
  },
};

const buildConfigUrl = () => {
  return (
    baseUrl +
    configurationEndpoints[configEnvironment] +
    (configEnvironment === CONFIG_ENVIRONMENTS.PROD ? appVersion + '/' : '') +
    configName
  );
};

export const configApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    config: builder.query<EnvironmentConfig, void>({
      keepUnusedDataFor: Infinity,
      queryFn: async () => {
        const fallback = defaultConfigs[configEnvironment];
        try {
          if (getUseLocalConfig()) {
            return { data: fallback };
          }

          const response = await axios.get(buildConfigUrl());
          if (!response.data) {
            return { data: fallback };
          }

          return { data: response.data };
        } catch (error: unknown) {
          return { data: fallback };
        }
      },
    }),
  }),
});

export const selectConfig = createSelector(configApiSlice.endpoints.config.select(), (result) =>
  structuredClone(result.data ?? defaultConfigs[configEnvironment]),
);

export const selectOperatorGatewayProxyConfig = createSelector(
  selectConfig,
  ({ mlbOperatorGatewayProxy }) => mlbOperatorGatewayProxy,
);

export const selectOktaConfig = createSelector(selectConfig, ({ oktaConfig }) => {
  const redirectUri =
    typeof window !== 'undefined' ? `${window.location.origin}/implicit/callback` : '';

  return { ...oktaConfig, redirectUri };
});

export const selectStatsBaseUrl = createSelector(selectConfig, ({ statsApiRoot }) => statsApiRoot);

export const selectListenLinesConfig = createSelector(
  selectConfig,
  ({ listenLinesConfig }) => listenLinesConfig,
);

export const selectDataDogConfig = createSelector(selectConfig, ({ dataDog }) => {
  const logsConfig = { ...dataDog.logsConfig, env: configEnvironment, version: appVersion };
  const rumConfig = { ...dataDog.rumConfig, env: configEnvironment, version: appVersion };
  return { logsConfig, rumConfig };
});

export const selectToolsConfig = createSelector(selectConfig, ({ tools }) => tools);

export const selectLoggerConfig = createSelector(selectToolsConfig, (tools) => tools.logger);

export const defaultConfig = defaultConfigs[configEnvironment];

export const { useConfigQuery } = configApiSlice;
