import { ApolloError } from '@apollo/client';
import { AlertColor, BoxProps, styled } from '@mui/material';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { GraphQLErrorExtensions, GraphQLFormattedError } from 'graphql';
import { closeSnackbar, CustomContentProps, SnackbarContent } from 'notistack';
import { forwardRef, SyntheticEvent } from 'react';

export interface ApolloErrorMessageDisplayProps extends CustomContentProps {
  color: AlertColor | undefined;
  error: ApolloError;
  title: string;
}

export const buildClientErrorMessages = (clientErrors: readonly Error[]) =>
  clientErrors.map((err, idx) => {
    const { message } = err;
    return <li key={`gql-error-message-${idx}`}>{`${message}`}</li>;
  });

export const buildGqlErrorMessages = (gqlErrors: readonly GraphQLFormattedError[]) =>
  gqlErrors.map((err, idx) => {
    const { extensions, message } = err;
    const { classification, code, executionId } = extensions ?? {
      classification: undefined,
      code: undefined,
      executionId: undefined,
    };
    return (
      <li key={`gql-error-message-${idx}`}>{`${message}${
        extensions
          ? `${classification ? ` - ${classification}` : ''} ${code ? ` code: ${code}` : ''} ${
              executionId ? ` id: ${executionId}` : ''
            }`
          : ''
      }`}</li>
    );
  });

export const buildProtocolErrors = (
  gqlErrors: readonly { extensions?: GraphQLErrorExtensions[] | undefined; message: string }[],
) =>
  gqlErrors.map((err, idx) => {
    const { message } = err;
    return <li key={`gql-error-message-${idx}`}>{message}</li>;
  });

const ErrorList = styled((props) => <Box component="ul" {...props} />)<BoxProps>(({ theme }) => ({
  margin: theme.spacing(1, 0, 1, 0),
  paddingLeft: theme.spacing(2),
}));

const ApolloErrorMessageDisplay = forwardRef<HTMLDivElement, ApolloErrorMessageDisplayProps>(
  (props, ref) => {
    const { color = 'error', error, id, title = 'Error' } = props;
    const { clientErrors = [], graphQLErrors = [], networkError, protocolErrors = [] } = error;
    const clientErrorMessages = buildClientErrorMessages(clientErrors);
    const gqlErrorMessages = buildGqlErrorMessages(graphQLErrors);
    const protocolErrorMessages = buildProtocolErrors(protocolErrors);

    const handleClose = (_: SyntheticEvent | Event, reason?: string) => {
      if (reason === 'clickaway') {
        return;
      }
      closeSnackbar(id);
    };

    return (
      <SnackbarContent ref={ref}>
        <Alert severity={color} variant="filled" onClose={handleClose}>
          {title ? <Typography fontWeight="bold">{title}</Typography> : null}
          {networkError ? <Typography>{networkError.message}</Typography> : null}
          {clientErrorMessages.length ? (
            <>
              <Typography>Client Errors</Typography>
              <ErrorList>{clientErrorMessages}</ErrorList>
            </>
          ) : null}
          {gqlErrorMessages.length ? (
            <>
              <Typography>GraphQL Errors</Typography>
              <ErrorList>{gqlErrorMessages}</ErrorList>
            </>
          ) : null}
          {protocolErrorMessages.length ? (
            <>
              <Typography>Protocol Errors</Typography>
              <ErrorList>{protocolErrorMessages}</ErrorList>
            </>
          ) : null}
        </Alert>
      </SnackbarContent>
    );
  },
);
ApolloErrorMessageDisplay.displayName = 'ApolloErrorMessageDisplay';

export default ApolloErrorMessageDisplay;
