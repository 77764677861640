import { ApolloError } from '@apollo/client';
import { MediaFeedType } from '@optools/services/operator-gateway/types/generated/graphql';
import { useSnackbar } from 'notistack';
import { useCallback } from 'react';

import { CreateMessageArgs, Message, MessageColor } from './types';

export const createMessage = ({
  autoHideDuration = 15000,
  body,
  color = MessageColor.DEFAULT,
  horizontal = 'left',
  persist = false,
  preventDuplicate = true,
  side,
  title,
  vertical = 'bottom',
}: CreateMessageArgs): Message => {
  let anchorOrigin;

  switch (side) {
    case MediaFeedType.HOME: {
      anchorOrigin = { horizontal: 'right', vertical: vertical || 'bottom' };
      break;
    }
    case MediaFeedType.AWAY: {
      anchorOrigin = { horizontal: 'left', vertical: vertical || 'bottom' };
      break;
    }
    default: {
      anchorOrigin = { horizontal, vertical };
    }
  }

  return {
    anchorOrigin,
    autoHideDuration,
    body,
    color,
    persist,
    preventDuplicate,
    title,
  } as Message;
};

export const useMessageCenter = () => {
  const { closeSnackbar, enqueueSnackbar } = useSnackbar();

  const enqueueMessage = useCallback(
    (message: Message) => enqueueSnackbar(message.body, { ...message, variant: 'messageDisplay' }),
    [enqueueSnackbar],
  );

  const closeMessage = useCallback(
    (id?: string) => {
      closeSnackbar(id);
    },
    [closeSnackbar],
  );

  const sendMessage = useCallback(
    (message: CreateMessageArgs) => enqueueMessage(createMessage(message)),
    [enqueueMessage],
  );

  const sendApolloErrorMessage = useCallback(
    (error: ApolloError, options?: Omit<CreateMessageArgs, 'body'>) => {
      const message = createMessage({
        color: MessageColor.ERROR,
        horizontal: 'center',
        persist: true,
        preventDuplicate: false,
        title: 'Error',
        vertical: 'top',
        ...options,
      });
      enqueueSnackbar('', {
        ...message,
        error,
        variant: 'apolloErrorMessageDisplay',
      });
    },
    [enqueueSnackbar],
  );

  return {
    closeMessage,
    createMessage,
    enqueueMessage,
    sendApolloErrorMessage,
    sendMessage,
  };
};
