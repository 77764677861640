import { BaseQueryFn, createApi } from '@reduxjs/toolkit/dist/query/react';

function fakeBaseQuery<ErrorType>(): BaseQueryFn<void, never, ErrorType, object> {
  return function () {
    throw new Error(
      'When using `fakeBaseQuery`, all queries & mutations must use the `queryFn` definition syntax.',
    );
  };
}

export const apiSlice = createApi({
  baseQuery: fakeBaseQuery(),
  endpoints: () => ({}),
  reducerPath: 'api',
});
