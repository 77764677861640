import { useOktaAuth } from '@okta/okta-react';
import { appEnvironment, configEnvironment } from '@optools/services/config/appConfig';
import {
  getUseLocalConfig,
  setConfigEnv,
  setUseLocalConfig,
} from '@optools/services/config/storage';
import { APP_ENVIRONMENTS, CONFIG_ENVIRONMENTS } from '@optools/services/config/types';
import { Button } from '@optools/ui/button';
import { ModeToggle } from '@optools/ui/mode-toggle';
import { Popover, PopoverContent, PopoverTrigger } from '@optools/ui/popover';
import { Separator } from '@optools/ui/separator';
import { Switch } from '@optools/ui/switch';
import { ToggleGroup, ToggleGroupItem } from '@optools/ui/toggle-group';
import { Tooltip, TooltipContent, TooltipTrigger } from '@optools/ui/tooltip';
import { cn } from '@optools/ui/utils';
import { FileSliders, FolderDot, Rocket, Settings, Shield, SunMoon } from 'lucide-react';
import { useState } from 'react';

interface SettingsMenuProps {
  open: boolean;
}

export function SettingsMenu({ open }: SettingsMenuProps) {
  const { authState } = useOktaAuth();
  const isAuthenticated = !!authState?.isAuthenticated;

  const [useLocalConfigFlag, setUseLocalConfigFlag] = useState(getUseLocalConfig);

  const handleChangeEnvironment = (env: CONFIG_ENVIRONMENTS) => {
    if (env) {
      setConfigEnv(env);
      window.location.reload();
    }
  };

  const onUseLocalConfigChange = () => {
    setUseLocalConfigFlag((prevState) => {
      setUseLocalConfig(!prevState);
      return !prevState;
    });
    window.location.reload();
  };

  const handleCopyOktaId = () => {
    navigator.clipboard.writeText(authState?.idToken?.claims.sub ?? '');
  };

  const handleCopyAccessToken = () => {
    navigator.clipboard.writeText(authState?.accessToken?.accessToken ?? '');
  };

  return (
    <Tooltip {...(open && { open: false })}>
      <Popover>
        <PopoverTrigger asChild>
          <TooltipTrigger asChild>
            <Button
              aria-label="Settings"
              className={cn(
                'ease justify-start rounded-lg px-2.5 transition-all duration-300',
                open ? 'w-60' : 'w-10',
              )}
              variant="ghost">
              <Settings className="size-5 shrink-0" />
              <span
                className={cn(
                  'ml-2 transition-opacity duration-300',
                  open ? 'opacity-100 delay-100' : 'opacity-0',
                )}>
                Settings
              </span>
            </Button>
          </TooltipTrigger>
        </PopoverTrigger>

        <PopoverContent
          align="end"
          alignOffset={2}
          className="w-max space-y-4 p-3 text-sm"
          side="right"
          sideOffset={8}>
          <div className="flex items-center justify-between gap-3">
            <div className="flex items-center gap-2 font-medium">
              <Rocket className="size-4" />
              <h2>Version </h2>
            </div>
            <span>{APP_VERSION}</span>
          </div>

          {/* Theme */}
          <div className="flex items-center justify-between gap-4">
            <div className="flex items-center gap-2 font-medium">
              <SunMoon className="size-4" />
              <h2>Theme</h2>
            </div>
            <ModeToggle />
          </div>

          {appEnvironment === APP_ENVIRONMENTS.DEV && (
            <>
              {/* Local Config */}
              <div className="flex items-center justify-between gap-4">
                <div className="flex items-center gap-2 font-medium">
                  <FileSliders className="size-4" />
                  <label htmlFor="local-config">Local Config</label>
                </div>
                <Switch
                  checked={useLocalConfigFlag}
                  id="local-config"
                  onCheckedChange={onUseLocalConfigChange}
                />
              </div>

              <Separator />

              {/* Config Env */}
              <div>
                <div className="mb-3 flex items-center gap-2 font-medium">
                  <FolderDot className="size-4" />
                  <h2>Config Environment</h2>
                </div>
                <ToggleGroup
                  type="single"
                  value={configEnvironment}
                  onValueChange={handleChangeEnvironment}>
                  <ToggleGroupItem className="flex-1" value={CONFIG_ENVIRONMENTS.QA}>
                    QA
                  </ToggleGroupItem>
                  <ToggleGroupItem className="flex-1" value={CONFIG_ENVIRONMENTS.NPD}>
                    NPD
                  </ToggleGroupItem>
                </ToggleGroup>
              </div>
            </>
          )}

          {/*  Okta info */}
          {isAuthenticated && (
            <div>
              <div className="mb-3 flex items-center gap-2 font-medium">
                <Shield className="size-4" />
                <h2>Okta Info</h2>
              </div>

              <div className="grid grid-cols-2 items-center gap-1">
                <Button variant="secondary" onClick={handleCopyOktaId}>
                  ID
                </Button>

                <Button variant="secondary" onClick={handleCopyAccessToken}>
                  Access Token
                </Button>
              </div>
            </div>
          )}
        </PopoverContent>
      </Popover>

      <TooltipContent side="right" sideOffset={5}>
        Settings
      </TooltipContent>
    </Tooltip>
  );
}
