import { appEnvironment } from '@optools/services/config/appConfig';
import { APP_ENVIRONMENTS } from '@optools/services/config/types';
import { selectToolsConfig } from '@optools/store/configApiSlice';
import { useAppSelector } from '@optools/store/hooks';
import { checkGroupAuth } from '@optools/utils/auth';
import { useLocation } from 'react-router-dom';

import { useOktaProfile } from './useOktaProfile';

export const useAccessCheck = () => {
  const { profile } = useOktaProfile();
  const location = useLocation();
  const tools = useAppSelector(selectToolsConfig);

  const showAudit = checkGroupAuth(tools.audit.auth.groups, profile.groups);
  const showBtcTools = checkGroupAuth(tools.btcTools.auth.groups, profile.groups);
  const showEsni = tools.esni.enabled && checkGroupAuth(tools.esni.auth.groups, profile.groups);
  const showExpOverride = checkGroupAuth(tools.experienceOverride.auth.groups, profile.groups);
  const showIpOverride = checkGroupAuth(tools.ipOverride.auth.groups, profile.groups);
  const showMediaValidation = checkGroupAuth(tools.mediaValidation.auth.groups, profile.groups);
  const showMilestones = checkGroupAuth(tools.milestones.auth.groups, profile.groups);
  const showLogger = checkGroupAuth(tools.logger.auth.groups, profile.groups);
  const showPartnerStreamDelivery = checkGroupAuth(tools.partner.auth.groups, profile.groups);
  const showRed5 =
    location.search.indexOf('red5=true') > -1 &&
    appEnvironment !== APP_ENVIRONMENTS.PROD &&
    checkGroupAuth(tools.red5.auth.groups, profile.groups);

  return {
    showAudit,
    showBtcTools,
    showEsni,
    showExpOverride,
    showIpOverride,
    showLogger,
    showMediaValidation,
    showMilestones,
    showPartnerStreamDelivery,
    showRed5,
  };
};
