import Alert, { AlertColor } from '@mui/material/Alert';
import Typography from '@mui/material/Typography';
import { closeSnackbar, CustomContentProps, SnackbarContent } from 'notistack';
import { forwardRef, SyntheticEvent } from 'react';

export interface MessageDisplayProps extends CustomContentProps {
  color: AlertColor | undefined;
  title?: string;
}

const MessageDisplay = forwardRef<HTMLDivElement, MessageDisplayProps>((props, ref) => {
  const { color = 'info', id, message, title } = props;
  const handleClose = (_: SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    closeSnackbar(id);
  };
  return (
    <SnackbarContent ref={ref}>
      <Alert severity={color} variant="filled" onClose={handleClose}>
        {title ? <Typography fontWeight="bold">{title}</Typography> : null}
        {typeof message === 'string' ? <Typography>{message}</Typography> : message}
      </Alert>
    </SnackbarContent>
  );
});
MessageDisplay.displayName = 'MessageDisplay';

export default MessageDisplay;
