import { combineReducers, configureStore, PreloadedState } from '@reduxjs/toolkit';

import { apiSlice } from './apiSlice';
import btc from './btc-slice';
import logger from './loggerSlice';

export const apiMiddleware = [apiSlice.middleware];

export const rootReducer = combineReducers({
  btc,
  logger,
  [apiSlice.reducerPath]: apiSlice.reducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export const setupStore = (preloadedState?: PreloadedState<RootState>) =>
  configureStore({
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({ serializableCheck: false }).concat(apiMiddleware),
    preloadedState,
    reducer: rootReducer,
  });

export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore['dispatch'];
