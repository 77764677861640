import {
  ScheduleContent,
  ScheduleGame,
  SchedulePipeline,
} from '@optools/services/operator-gateway/types/schedule';
import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { isBefore } from 'date-fns';

import type { RootState } from './store';

export interface BTCGame extends Omit<ScheduleGame, 'pipelines' | 'content'> {
  content: string[];
  pipelines: number[];
}

export interface BTCState {
  content: Record<string, ScheduleContent>;
  games: Record<number, BTCGame>;
  pipelines: Record<number, SchedulePipeline>;
  schedule: number[];
}

export const initialState: BTCState = {
  content: {},
  games: {},
  pipelines: {},
  schedule: [],
};

export const btcSlice = createSlice({
  initialState,
  name: 'btc',
  reducers: {
    gameContentUpdated: (
      state: BTCState,
      action: PayloadAction<{ content: ScheduleContent[]; gamePk: number }>,
    ) => {
      const { content, gamePk } = action.payload;
      content.forEach((content) => {
        state.content[content.mediaId] = content;
      });
      const game = state.games[gamePk];
      if (game) game.content = content.map(({ mediaId }) => mediaId);
    },
    gamePipelinesRefreshed: (
      state: BTCState,
      action: PayloadAction<{ gamePk: number; pipelines: SchedulePipeline[] }>,
    ) => {
      const { gamePk, pipelines } = action.payload;
      pipelines.forEach((pipeline) => (state.pipelines[pipeline.id] = pipeline));
      const game = state.games[gamePk];
      if (game) game.pipelines = action.payload.pipelines.map(({ id }) => id);
    },
    gameUpdated: (state: BTCState, action: PayloadAction<ScheduleGame[]>) => {
      const schedule = [...action.payload];
      const content = action.payload.flatMap(({ content }) => content);
      const pipelines = action.payload.flatMap(({ pipelines }) => pipelines);

      schedule.sort((a, b) => {
        return isBefore(new Date(a.gameDate), new Date(b.gameDate)) ? -1 : 1;
      });

      state.schedule = schedule.map(({ gamePk }) => gamePk);

      state.games = Object.fromEntries(
        action.payload.map((game) => {
          const pipelines = game.pipelines.map(({ id }) => id).filter((id) => !!id);
          const content = game.content.map(({ mediaId }) => mediaId);
          return [game.gamePk, { ...game, content, pipelines }];
        }),
      );

      state.pipelines = Object.fromEntries(pipelines.map((pipeline) => [pipeline.id, pipeline]));

      state.content = Object.fromEntries(content.map((item) => [item.mediaId, item]));
    },
    gamesReceived: (state: BTCState, action: PayloadAction<ScheduleGame[]>) => {
      const schedule = [...action.payload];
      const content = action.payload.flatMap(({ content }) => content);
      const pipelines = action.payload.flatMap(({ pipelines }) => pipelines);

      schedule.sort((a, b) => {
        return isBefore(new Date(a.gameDate), new Date(b.gameDate)) ? -1 : 1;
      });

      state.schedule = schedule.map(({ gamePk }) => gamePk);

      state.games = Object.fromEntries(
        action.payload.map((game) => {
          const pipelines = game.pipelines.map(({ id }) => id).filter((id) => !!id);
          const content = game.content.map(({ mediaId }) => mediaId);
          return [game.gamePk, { ...game, content, pipelines }];
        }),
      );

      state.pipelines = Object.fromEntries(pipelines.map((pipeline) => [pipeline.id, pipeline]));

      state.content = Object.fromEntries(content.map((item) => [item.mediaId, item]));
    },
    pipelineRemoved: (
      state: BTCState,
      action: PayloadAction<{ gamePk: number; pipelineId: number }>,
    ) => {
      const { gamePk, pipelineId } = action.payload;
      delete state.pipelines[pipelineId];
      const game = state.games[gamePk];
      if (game) game.pipelines = game.pipelines.filter((id) => id !== action.payload.pipelineId);
    },
    pipelinesUpdated: (state: BTCState, action: PayloadAction<SchedulePipeline[]>) => {
      action.payload.forEach((pipeline) => {
        state.pipelines[pipeline.id] = pipeline;
      });
    },
  },
});

export const {
  gameContentUpdated,
  gamePipelinesRefreshed,
  gamesReceived,
  pipelineRemoved,
  pipelinesUpdated,
} = btcSlice.actions;

const selectBtc = (state: RootState) => state.btc;

export const selectSchedule = createSelector([selectBtc], (btc) => btc.schedule);

export const selectGame = (state: RootState, gamePk: number) => state.btc.games[gamePk];

export const selectPipelines = createSelector([selectBtc], (btc) => btc.pipelines);

export const selectGamePipelines = createSelector(
  [(state: RootState, gamePk: number) => selectGame(state, gamePk), selectPipelines],
  (game, pipelines) => game.pipelines.map((id) => pipelines[id]).filter(Boolean),
);

export const selectContent = createSelector([selectBtc], (btc) => btc.content);

export const selectGameContent = createSelector(
  [
    (state: RootState, gamePk: number) => selectGame(state, gamePk),
    (state: RootState) => selectBtc(state).content,
  ],
  (game, content) => game.content.map((mediaId) => content[mediaId]).filter(Boolean),
);

export const selectMediaIds = createSelector([selectContent], (content) =>
  Object.values(content).map(({ mediaId }) => mediaId),
);

export default btcSlice.reducer;
