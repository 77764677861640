import { gql } from '../types/generated/gql';

export const FEED_POLICY_FRAGMENT = gql(`
  fragment FeedPolicyFields on FeedPolicy {
    broadcastTime
    callSign
    feedSubType
    feedType
    mediaId
    mediaType
  }
`);

export const VIEWING_POLICY_FRAGMENT = gql(`
  fragment ViewingPolicyFields on ViewingPolicy {
    id
    href
    action
    actionLabel
    name
    audienceHref
  }
`);
