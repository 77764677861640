import { LogsInitConfiguration } from '@datadog/browser-logs';
import { RumInitConfiguration } from '@datadog/browser-rum';
import config from '@optools/data/application-configs/prod/config.json';

export interface MlbGatewayProxy {
  baseUrl: string;
  endpoint: string;
  endpoints: Record<string, string>;
  wssBase: string;
}

export interface EnvironmentConfig {
  dataDog: DataDogConfig;
  listenLinesConfig: ListenLinesConfig;
  mlbOperatorGatewayProxy: MlbGatewayProxy;
  oktaConfig: typeof config.oktaConfig;
  statsApiRoot: typeof config.statsApiRoot;
  tools: typeof config.tools;
}

export interface ListenLinesItem {
  code: string;
  network: string;
  number: string;
  team: string;
}

export interface ListenLinesConfig {
  listenLines: Array<ListenLinesItem>;
}

export interface MLBLogsInitConfiguration extends LogsInitConfiguration {
  enabled?: boolean;
}

export interface MLBRumInitConfiguration extends RumInitConfiguration {
  enabled?: boolean;
}

export interface DataDogConfig {
  logsConfig: MLBLogsInitConfiguration;
  rumConfig: MLBRumInitConfiguration;
}

export enum APP_ENVIRONMENTS {
  DEV = 'dev',
  NPD = 'npd',
  PROD = 'prod',
  QA = 'qa',
}

export enum CONFIG_ENVIRONMENTS {
  NPD = 'npd',
  PROD = 'prod',
  QA = 'qa',
}
