import { BoxProps, styled } from '@mui/material';
import Box from '@mui/material/Box';

export const PageContainer = styled(Box)<BoxProps>({
  display: 'grid',
  gridTemplateRows: 'auto 1fr',
  height: '100%',
  position: 'relative',
  width: '100%',
});

export const OutletContainer = styled(Box)<BoxProps>(({ theme }) => ({
  height: '100%',
  maxHeight: '100%',
  overflow: 'auto',
  padding: theme.spacing(2),
}));
