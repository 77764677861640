import { LucideProps } from 'lucide-react';
import { Link, LinkProps } from 'react-router-dom';

import { Card } from './card';

export interface ToolTileProps {
  icon: React.ForwardRefExoticComponent<
    Omit<LucideProps, 'ref'> & React.RefAttributes<SVGSVGElement>
  >;
  title: string;
  to: LinkProps['to'];
}

function ToolTile({ icon: Icon, title, to }: ToolTileProps) {
  return (
    <Link to={to}>
      <Card className="flex aspect-square flex-col items-center justify-center gap-4 p-3 transition-colors hover:bg-muted">
        <Icon className="size-8" />
        <p className="text-center text-sm">{title}</p>
      </Card>
    </Link>
  );
}

export default ToolTile;
