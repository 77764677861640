import { APP_ENVIRONMENTS, CONFIG_ENVIRONMENTS } from './types';
import { determineAppEnvironment } from './utils';

// Storage keys
export const CONFIG_ENV_STORAGE_KEY = 'configEnv';
export const USE_LOCAL_CONFIG_STORAGE_KEY = 'useLocalConfig';

export const getConfigEnv = (): CONFIG_ENVIRONMENTS => {
  const item = localStorage.getItem(CONFIG_ENV_STORAGE_KEY);
  const env = item ? JSON.parse(item) : CONFIG_ENVIRONMENTS.QA;
  return env;
};

export const setConfigEnv = (env: CONFIG_ENVIRONMENTS): void => {
  localStorage.setItem(CONFIG_ENV_STORAGE_KEY, JSON.stringify(env));
};

// Get the flag that determines if the application should use local or remote configuration
// the behavior is as follows:
// DEV: Default to using local config.
// QA, NPD, PROD: Default to using hosted config.
export const getUseLocalConfig = (): boolean => {
  const flag = localStorage.getItem(USE_LOCAL_CONFIG_STORAGE_KEY);
  return flag
    ? JSON.parse(flag)
    : determineAppEnvironment() === APP_ENVIRONMENTS.DEV
      ? true
      : false;
};

export const setUseLocalConfig = (useLocalConfig: boolean) => {
  localStorage.setItem(USE_LOCAL_CONFIG_STORAGE_KEY, JSON.stringify(useLocalConfig));
};
