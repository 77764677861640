import { gql } from './types/generated/gql';

export const AUDIT_VIEW_FRAGMENT = gql(`
  fragment AuditViewFields on AuditView {
    traceId
    createdAt
    sourceSystem
    userId
    userEmail
    resourceTags {
      name
      value
    }
    operation
  }
`);
