import { MediaFeedType } from '@optools/services/operator-gateway/types/generated/graphql';
import { OptionsObject } from 'notistack';
import { ReactNode } from 'react';

export enum MessageColor {
  DEFAULT = 'default',
  ERROR = 'error',
  INFO = 'info',
  SUCCESS = 'success',
  WARNING = 'warning',
}

export interface Message extends OptionsObject<'messageDisplay'> {
  body: string;
  color?: MessageColor;
  gamePk?: number;
  title: string;
}

export interface CreateMessageArgs {
  autoHideDuration?: number | null | undefined;
  body?: string | ReactNode;
  color?: MessageColor;
  horizontal?: string;
  persist?: boolean;
  preventDuplicate?: boolean;
  side?: MediaFeedType;
  title: string;
  vertical?: string;
}
