import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';
import { useOktaAuth } from '@okta/okta-react';
import { MLBLogsInitConfiguration, MLBRumInitConfiguration } from '@optools/services/config/types';
import { useEffect } from 'react';

import { UserInfo } from '../types';

export interface UseDataDogOnLoginProps {
  logsConfig?: MLBLogsInitConfiguration;
  rumConfig?: MLBRumInitConfiguration;
  userInfo?: UserInfo;
}

export const useDataDogOnLogin = ({ logsConfig, rumConfig, userInfo }: UseDataDogOnLoginProps) => {
  const { authState } = useOktaAuth();
  const isAuthenticated = !!authState?.isAuthenticated;

  /**
   * Initialize data dog based on the provided config
   */
  useEffect(() => {
    // If rum is enabled in the runtime config, and we have an applicationid,
    // and we don't have a init config already (DD already initialized), and the user is auth'd
    const shouldInitDataDog =
      rumConfig?.enabled &&
      !!rumConfig?.applicationId &&
      !datadogRum.getInitConfiguration() &&
      isAuthenticated;

    if (shouldInitDataDog) {
      datadogRum.init(rumConfig);

      return () => {
        datadogRum.stopSessionReplayRecording();
        datadogRum.stopSession();
      };
    }
  }, [isAuthenticated, rumConfig]);

  useEffect(() => {
    const shouldInitLogs =
      logsConfig?.enabled &&
      !!logsConfig?.clientToken &&
      !datadogLogs.getInitConfiguration() &&
      isAuthenticated;

    if (shouldInitLogs) datadogLogs.init(logsConfig);
  }, [isAuthenticated, logsConfig]);

  /**
   * Handle setting user data once we know it
   */
  useEffect(() => {
    if (userInfo && isAuthenticated) {
      datadogRum.setUser({ ...userInfo });
    }
  }, [isAuthenticated, userInfo]);

  return { datadogLogs, datadogRum };
};
