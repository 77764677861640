import { PageLoader } from '@optools/legacy-ui/components/loading/PageLoader';
import { useConfigQuery } from '@optools/store/configApiSlice';
import { Toaster } from '@optools/ui/notifications/sonner';

import { Dashboard } from './dashboard/dashboard';
import DataDogWrapper from './DataDog';
import { ErrorBoundary } from './ErrorBoundary';
import Providers from './Providers';
import Routes from './Routes';

export default function App() {
  const { isLoading, isUninitialized } = useConfigQuery();

  if (isUninitialized || isLoading) return <PageLoader withDelay />;

  return (
    <Providers>
      <ErrorBoundary>
        <DataDogWrapper>
          <Toaster richColors />
          <Dashboard>
            <Routes />
          </Dashboard>
        </DataDogWrapper>
      </ErrorBoundary>
    </Providers>
  );
}
