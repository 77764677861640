import { Game } from '@optools/services/operator-gateway/types/game';
import { Slate } from '@optools/services/operator-gateway/types/generated/graphql';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import type { RootState } from './store';

export const GAME_SECTION = {
  GAME: 'Game',
  NONE: '',
  POST_GAME: 'Post-Game',
  PRE_GAME: 'Pre-Game',
  STREAM_START: 'Stream Start',
  TYFW: 'Thank You For Watching',
} as const;

export interface LoggerState {
  game: Game | null;
  gameSection: (typeof GAME_SECTION)[keyof typeof GAME_SECTION];
  slates: Slate[];
}

export const initialState: LoggerState = {
  game: null,
  gameSection: GAME_SECTION.PRE_GAME,
  slates: [],
};

export const loggerSlice = createSlice({
  initialState,
  name: 'logger',
  reducers: {
    reset: () => initialState,
    setGame: (state: LoggerState, action: PayloadAction<Game | null>) => {
      state.game = action.payload;
    },
    setSlateByType: (state: LoggerState, action: PayloadAction<Slate>) => {
      const { slates } = state;
      const slate = action.payload;
      const slateExists = slates.some((es: Slate) => es.id === slate.id);
      if (!slateExists) {
        const slates = [...new Set([...state.slates, slate])];
        state.slates = slates.sort((a: Slate, b: Slate) => {
          if (a.type > b.type) return -1;
          if (a.type < b.type) return 1;
          return 0;
        });
      }
    },
  },
});

export const { reset, setGame, setSlateByType } = loggerSlice.actions;

export const selectSlates = (state: RootState) => state.logger.slates;
export const selectCurrentGame = (state: RootState) => state.logger.game;

export default loggerSlice.reducer;
