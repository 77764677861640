import { useOktaAuth } from '@okta/okta-react';
import { PageLoader } from '@optools/legacy-ui/components/loading/PageLoader';
import { useBrowserStorage } from '@optools/legacy-ui/hooks/useBrowserStorage';
import { useOktaProfile } from '@optools/legacy-ui/hooks/useOktaProfile';
import { ReactNode } from 'react';

import { Sidebar } from './components/sidebar';

export function Dashboard({ children }: { children: ReactNode }) {
  const { isLoading, isUninitialized } = useOktaProfile();
  const { authState, oktaAuth } = useOktaAuth();

  const isInitializing = !authState || (authState?.isAuthenticated && isUninitialized) || isLoading;

  const content = isInitializing ? <PageLoader /> : children;

  const [open, setOpen] = useBrowserStorage('localStorage', 'sidebarOpen', false);
  return (
    <div className="grid h-screen w-full grid-cols-[auto_1fr]">
      <Sidebar open={open} onToggleOpen={() => setOpen((current) => !current)} />
      <main className="relative flex-1 gap-4 overflow-auto">
        {/* Render the routes so that the LoginCallback can parse the tokens */}
        {oktaAuth.isLoginRedirect() ? children : content}
      </main>
    </div>
  );
}
