import { appEnvironment } from '@optools/services/config/appConfig';
import { APP_ENVIRONMENTS } from '@optools/services/config/types';
import { cn } from '@optools/ui/utils';
import { matchPath, NavLink, To, useLocation, useResolvedPath } from 'react-router-dom';

export interface SubNavigationLinkProps {
  label: string;
  to: To;
}

function SubNavigationLink({ label, to }: SubNavigationLinkProps) {
  const location = useLocation();
  const resolvedPath = useResolvedPath(to);
  const match = matchPath({ path: resolvedPath.pathname }, location.pathname);

  const isLowerEnvironment =
    appEnvironment === APP_ENVIRONMENTS.QA || appEnvironment === APP_ENVIRONMENTS.NPD;

  return (
    <NavLink
      key={label}
      className={cn(
        'whitespace-nowrap text-sm text-muted-foreground transition-colors duration-200',
        match ? 'text-foreground' : 'text-muted-foreground',
        isLowerEnvironment ? (match ? 'text-white' : 'text-white/70') : '',
      )}
      to={to}>
      {label}
    </NavLink>
  );
}

export interface SubNavigationProps {
  links?: SubNavigationLinkProps[];
  title: string;
}

export function SubNavigation({ links, title }: SubNavigationProps) {
  return (
    <header
      className={cn('grid min-h-[57px] w-full items-center border-b md:flex md:gap-4', {
        'bg-[#813BF6] text-white': appEnvironment === APP_ENVIRONMENTS.QA,
        'bg-[#61082B] text-white': appEnvironment === APP_ENVIRONMENTS.NPD,
      })}
      id="sub-navigation">
      <div className="flex min-h-[56px] items-center pl-4 md:min-h-0">
        <h1 className="whitespace-nowrap text-xl font-semibold md:p-0">{title}</h1>
      </div>

      {links && (
        <nav className="flex w-full flex-1 items-center gap-4 overflow-x-auto border-t px-4 py-3 md:border-t-0">
          {links.map(({ label, to }) => (
            <SubNavigationLink key={label} label={label} to={to} />
          ))}
        </nav>
      )}
    </header>
  );
}
