import { PaletteMode, useMediaQuery } from '@mui/material';
import { createTheme, ThemeOptions, ThemeProvider } from '@mui/material/styles';
import { useTheme } from '@optools/ui/theme-provider';
import { ReactNode, useMemo } from 'react';

declare module '@mui/material/styles' {
  interface TypographyVariants {
    debug: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    debug?: React.CSSProperties;
  }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    debug: true;
  }
}

export interface ThemeProps {
  children: ReactNode;
}

const components: ThemeOptions['components'] = {
  MuiCssBaseline: {
    styleOverrides: `
      *:not(.with-scrollbar)::-webkit-scrollbar {
        display: none;
      }
    `,
  },
};

const typography: ThemeOptions['typography'] = {
  button: {
    fontWeight: 'normal',
    textTransform: 'none' as const,
  },
  debug: {
    border: 'dashed 0.5px',
    borderRadius: '5px',
    fontFamily: 'monospace',
    padding: '0px 4px',
  },
  fontFamily: ['Inter var', 'sans-serif'].join(','),
  fontSize: 12,
  h5: {
    fontWeight: 'bold',
  },
  h6: {
    fontWeight: 'bold',
  },
};

const darkPalette: ThemeOptions['palette'] = {
  background: {
    paper: '#404040',
  },
  divider: 'rgba(26,26,26,0.59)',
  info: {
    main: 'rgb(51, 87, 142)',
  },
  mode: 'dark' satisfies PaletteMode,
  primary: {
    main: '#3c85fb',
  },
  secondary: {
    main: '#e43d48',
  },
};

const lightPalette: ThemeOptions['palette'] = {
  background: {
    paper: '#E5E1E6',
  },
  divider: 'rgba(167,168,169,0.6)',
  info: {
    main: 'rgb(51, 87, 142)',
  },
  mode: 'light' satisfies PaletteMode,
  primary: {
    main: '#002D72',
  },
  secondary: {
    main: '#d50032',
  },
};

const getDesignTokens = (mode: PaletteMode): ThemeOptions => {
  if (mode === 'dark') {
    return {
      components,
      palette: darkPalette,
      typography,
    };
  }
  return {
    components,
    palette: lightPalette,
    typography,
  };
};

export default function Theme({ children }: ThemeProps) {
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

  const { theme: mode } = useTheme();

  const theme = useMemo(() => {
    const paletteMode = mode === 'system' ? (prefersDarkMode ? 'dark' : 'light') : mode;
    return createTheme(getDesignTokens(paletteMode));
  }, [mode, prefersDarkMode]);

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
}
